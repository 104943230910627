import axiosInstance from "../lib/axiosInstance";

class ordersModel {

    constructor() {
        this.base_url = "/vendor_orders"
        this.base_url2 = "/reports/order"
    }
    async list(query = []) {

        return (await axiosInstance.get(`${this.base_url}?${new URLSearchParams(query)}`)).data;
    }
    async create(data = []) {
        return await axiosInstance.post(this.base_url, data);
    }
    async update(id, data = []) {
        return await axiosInstance.post(`${this.base_url}/${id}?_method=PUT`, data);
    }
    async destroy(id, data = []) {
        return await axiosInstance.delete(`${this.base_url}/${id}`, data);
    }
    async show(id) {
        return (await axiosInstance.get(`${this.base_url}/${id}`)).data;
    }
    async ExportOrder(data=[]) {
        return (await axiosInstance.post(`${this.base_url2}`,data));
    }
    async pack(id, sub_order_id) {
        return await axiosInstance.post(`${this.base_url}/${id}/${sub_order_id}/pack`);
    }

    async packUpdate(id, sub_order_id) {
        return await axiosInstance.post(`${this.base_url}/${id}/${sub_order_id}/update`);
    }
}

export default new ordersModel();